/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
require('../../libs/nasmo/nasmo-parallax.js');
require('../../libs/nasmo/nasmo-scrollTo.js');
//require('dropzone');
//require('../../libs/jquery-sweetalert/sweetalert-dev.js');
//require('resources/assets/js/libs/jquery-migrate/jquery-migrate.min.js');
require('../../libs/jquery-ui.min.js');
//require('../../libs/jquery-cropit/jquery.cropit.js');
require('../../libs/jquery-mask/jquery.mask.js');
//require('../../libs/bootstrap-select/bootstrap-select.js');
//require('../../libs/bootstrap-select/defaults-fr_FR.js');
require('../../libs/bootstrap-switch/bootstrap-switch.min.js');
require('../../libs/jquery.matchHeight.js');
require('../../libs/wow.min.js');
//require('bootstrap-datepicker');
//require('datatables');

require('lightgallery');
require('hideseek');
//require('masonry-layout');
//require('jquery-mousewheel');
//require('malihu-custom-scrollbar-plugin');