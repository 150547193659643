$(function() {
    wow = new WOW(
      {
        animateClass: 'animated',
        offset:       100,
        callback:     function(box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
    $('[data-toggle="tooltip"]').tooltip();
    
    $('[data-toggle="popover"]').popover();
    
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });
    
    $(".lightGallery").lightGallery();
    $('table').each(function(){
       $(this).find('td').css('padding',$(this).attr('cellpadding')+'px');
       $(this).find('td').css('margin',$(this).attr('cellspacing')+'px');
    });

    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }
        
    });
    
    $(".radio-switch").bootstrapSwitch({
        size : 'small'
    });
    
    $('.money').mask("# ##0.00", {reverse: true});
    
    $('#preloader').fadeOut(1000, function() {
        $('#preloader').html('');
    });

    $(".remonte").click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    });
    var headerOpen = false;               // HEADER MASQUÉ PAR DÉFAUT S'AFFICHE AU SCROLL
    $(window).scroll(function() {
        var value = jQuery(this).scrollTop(); 
        if ( value > 50 ) {
            $("#remonte").css( "opacity","0.8");
            if (headerOpen == false) {
                headerOpen = true;
                $("#header").css( "background","#FFF");
                $("#header").addClass( "shadowed");
                $("#header").css( "opacity","0.95");
            }
        } else {
            $("#remonte").css( "opacity","0");
            $("#header").css( "background","transparent");
                $("#header").removeClass( "shadowed");
            $("#header").css( "opacity","0.9");
            headerOpen = false;
        }
        
    }); 
    
        
    if($('.royalSlider').length){
    
        mySliderInstance=$('.royalSlider').royalSlider({
            loop:true,
            controlNavigation:"none",
            addActiveClass: true,
            controlsInside : true,
            arrowsNav: true,
            arrowsNavAutoHide: false,
            controlNavigation: 'none',
            autoScaleSlider: true,
            autoScaleSliderWidth: '100%',
            autoScaleSliderHeight: '95%',
            controlNavigation: 'bullets',
            fadeinLoadedSlide: false,
            globalCaption: true,
            keyboardNavEnabled: true,
            globalCaptionInside: true,
            navigateByClick: false,
            sliderDrag:false,
            /*fullscreen: {
        		// fullscreen options go gere
        		enabled: true,
        		nativeFS: false
        	},*/
            autoPlay: {
                // autoplay options go gere
                enabled: false,
                pauseOnHover: true,
                dalay: 4000
            },

            visibleNearby: {
                enabled: false,
                centerArea: 0.4,
                center: true,
                globalCaption: true,
                keyboardNavEnabled: true,
                globalCaptionInside: false,
            }
        }).data('royalSlider'); 
        mySliderInstance.ev.on('rsBeforeAnimStart', function(event) {
            $('.service-picto').removeClass('active');
            $('#picto-' + mySliderInstance.currSlideId).addClass('active');
      });       
    }
        
    $('#hideseeksearch').hideseek({
        list: '.hideseek-list',
        /*highlight: true,*/
		ignore_accents: true
    });
    
    $('.same-height').matchHeight();
    
});