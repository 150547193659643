$('a.scrollTo').click(function(e) {
    e.preventDefault();
    $('.navbar-collapse.collapse.in').removeClass('in'); // pour fermer le burger menu sur mobiles
    var dest = $(this).attr('href').replace('#','');
    var offset = $('#'+dest).offset();
    var stateObj = { site: "Draillard" };
	history.pushState(stateObj, "Home", dest);
    $('html, body').animate({
		scrollTop: (offset.top-parseInt($('body').css('margin-top'))-parseInt($('#header').css('height')))+'px'
	}, 500);
	$('.scrollTo').removeClass('active');
	$(this).addClass('active');
});

$('a.scrollDown').click(function(e) {
    e.preventDefault();
    $('.navbar-collapse.collapse.in').removeClass('in'); // pour fermer le burger menu sur mobiles
    $('html, body').animate({
		scrollTop: +($(window).outerHeight()-parseInt($('#header').css('height')))
	}, 500);
});