function init_parallax() {  
    originalHeight = $(window).outerHeight();   
    $(".nasmo-parallax").each(function() {
        var Offset = $(this).offset();
        originalTop[$(this).attr('id')] = Offset.top;
        var box=$(this);     
        box.css('background-repeat','no-repeat').css('background-size','cover').css('basckground-position', 'top');
    
    });
}

$(window).scroll(function(){
    windowPosition=$(window).scrollTop();
    $(".nasmo-parallax").each(function() {
        elementPosition = $(this).offset();
        if ((windowPosition+$(window).outerHeight())>elementPosition.top) {
            $(this).css('background-position','0px '+(((windowPosition-originalTop[$(this).attr('id')])*0.4))+'px');
        }
    });  
})

var originalTop = [];
var originalHeight = 0;
$(function () {
    
    init_parallax();
});